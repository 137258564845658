import styles from './Footer.module.css';

import { useUI } from 'hooks';
import { useContext } from 'react';
import { LangContext } from 'context';

const localization = {
  footer1: {
    en: 'This project is a collaboration between Novaya Gazeta Europe, the investigative project Sistema, and Transparency International Russia.',
    ru: 'Это совместный проект «Новой газеты Европа», расследовательского проекта «Система» и «Трансперенси Интернешнл — Россия».'
  },
  authors: {
    en: 'Authors:',
    ru: 'Авторы:'
  },
  contributors: {
    en: 'Participants:',
    ru: 'Принимали участие:'
  },
  editors: {
    en: 'Editors:',
    ru: 'Редакторы:'
  },
  footer2: {
    en: 'A.B. (Sistema), Lola Limonova, and the Novaya Gazeta Europe investigative office',
    ru: 'А.Б. («Система»), Лола Лимонова и отдел расследований «Новой газеты Европа»'
  },
  footer3: {
    en: 'Dmitry Sukharev (Sistema), Ilya Shumanov, and Rima Abu Zaalan (Transparency International Russia)',
    ru: 'Дмитрий Сухарев («Система»), Илья Шуманов и Рима Абу Залаан («Трансперенси Интернешнл — Россия»)'
  },
  footer4: {
    en: 'Andrey Soshnikov (Sistema), Arnold Khachaturov (Novaya Gazeta Europe)',
    ru: 'Андрей Сошников («Система»), Арнольд Хачатуров («Новая газета Европа»)'
  },
  footer5: {
    en: 'If you would like to report a mistake, or have additional information regarding foreign real estate owned by Russian politicians and oligarchs, please email contact@novayagazeta.eu',
    ru: 'Если вы заметили ошибки или обладаете дополнительной информацией о зарубежной недвижимости российских политиков и олигархов, напишите на почту «Новой газеты Европа» contact@novayagazeta.eu.'
  },
  footer6: {
    en: 'Russia’s Justice Ministry considers Novaya Gazeta Europe, Transparency International, and the RFE/RL to be “undesirable organisations”. Please be aware of the legal risks associated with distributing content from these websites and other related publications.',
    ru: 'Минюст РФ считает «Новую газету Европа», «Трансперенси Интернешнл» и корпорацию RFE/RL «нежелательными» организациями. Учитывайте юридические риски, связанные с распространением этой и других наших публикаций.'
  },
};

export default function Footer() {
  const { ref } = useUI('footer');
  const { lang } = useContext(LangContext);

  return (
    <div className={styles['footer']}>
      <div ref={ref} className={styles['footer-body']}>
        <div className={styles['footer-wrapper']}>
          <div className={styles['footer-text']}>
            <p className={styles['text_bold']}>
              {lang === 'RU' ? localization.footer1.ru : localization.footer1.en}
            </p>
            <br />
            <p>
              <span className={styles['text_bold']}>
                {lang === 'RU' ? localization.authors.ru : localization.authors.en}
              </span>{' '}
              {lang === 'RU' ? localization.footer2.ru : localization.footer2.en}
            </p>
            <br />
            <p>
              <span className={styles['text_bold']}>
                {lang === 'RU' ? localization.contributors.ru : localization.contributors.en}
              </span>{' '}
              {lang === 'RU' ? localization.footer3.ru : localization.footer3.en}
            </p>
            <br />
            <p>
              <span className={styles['text_bold']}>
                {lang === 'RU' ? localization.editors.ru : localization.editors.en}
              </span>{' '}
              {lang === 'RU' ? localization.footer4.ru : localization.footer4.en}
            </p>
            { lang === 'EN' ? <br /> : ''}
            {
              lang === 'EN' ? 
              <p>
                <span className={styles['text_bold']}>
                  Editors (English):
                </span>{' '}
                  Tom Masters, Desmond Tumulty, Anna Tumanova
              </p> 
              : ''
            }
            { lang === 'EN' ? <br /> : ''}
            {
              lang === 'EN' ? 
              <p>
                <span className={styles['text_bold']}>
                  Translators:
                </span>{' '}
                  Carter Lemen, Owen Gramley, Lucy Silbaugh, Alannah Millar, Desmond Tumulty
              </p> 
              : ''
            }
            <br />
            <p className={styles['text_bold']}>
              {lang === 'RU' ? localization.footer5.ru : localization.footer5.en}
            </p>
            <br />
            <p className={styles['footer-disclaimer']}>
              {lang === 'RU' ? localization.footer6.ru : localization.footer6.en}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
