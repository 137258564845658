import { AppContext } from 'context';

import { useContext } from 'react';

/**
 * 
 * @returns {Object}
 * @property {Object} places
 * @property {Object} person
 */
export const useApp = () => {
    const context = useContext(AppContext);
    return context;
};