import { createContext, useState, useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import useSWRImmutable from 'swr/immutable';
import { fetchData } from 'util/helper';
import { LangContext } from './langProvider';
import initialData from 'data/site/initial.json';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const { lang, setLang } = useContext(LangContext);
  let ruResp = useSWRImmutable('/data/app/data.ru.json', fetchData);
  let enResp = useSWRImmutable('/data/app/data.en.json', fetchData);
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [isPageText, setIsPageText] = useState(false);
  const [dataApp, setDataApp] = useState(initialData);
  
  useEffect(() => {
    if (lang == "RU")
      setDataApp(!ruResp.isLoading && ruResp.data ? ruResp.data : initialData)
    else
      setDataApp(!enResp.isLoading && enResp.data ? enResp.data : initialData)
  }, [lang, enResp.isLoading, ruResp.isLoading])

  return (
    <AppContext.Provider
      value={{
        ...dataApp,
        isMobile,
        isPageText,
        setIsPageText,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
