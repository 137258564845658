import { useContext, useEffect } from 'react';

import { useInView } from 'react-intersection-observer';
import { UIContext } from 'context';

export const useUI = (nameComponent) => {
  const { setColorLang } = useContext(UIContext);

  const { ref, inView } = useInView({
    threshold: 0.9,
  });

  useEffect(() => {
    setColorLang(inView, nameComponent);
  }, [inView, setColorLang, nameComponent]);

  return { ref };
};
