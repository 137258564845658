import styles from './CardHead.module.css';
import clsx from 'clsx';

function CardHead({
  name,
  surname,
  descrip_short,
  sanctions,
  war,
  description,
  owner_group_ru,
  owner_photo,
  handleClose,
}) {
  const imgSrc = `oligarchs/${owner_photo}`;

  return (
    <>
      <div className={styles['card-block']}>
        <div className={styles['card-info']}>
          <div className={styles['image-block_window']}>
            <img
              className={styles['image-block__img']}
              loading="lazy"
              src={`/images/${imgSrc}`}
            />
          </div>
          <div>
            <div className={styles['card__title']}>
              <div>{name}</div>
              <div>{surname}</div>
            </div>
            {owner_group_ru && (
              <div className={styles['card-status-person']}>
                {owner_group_ru}
              </div>
            )}
          </div>
        </div>
        <div className={styles['card__text']}>
          <div className={styles['card__facts']}>
            <div
              className={clsx(
                styles['card_facts-block'],
                styles['card_facts-block-one']
              )}
            >
              <span className={styles['card_facts-title']}>Санкции: </span>
              <span className={styles['card_facts-text']}>{sanctions}</span>
            </div>
            <div
              className={clsx(
                styles['card_facts-block'],
                styles['card_facts-block-two']
              )}
            >
              <span className={styles['card_facts-title']}>О войне: </span>
              <span
                className={styles['card_facts-text']}
                dangerouslySetInnerHTML={{ __html: war }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='separator-quill' />
      <div
        className={styles['card_description']}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </>
  );
}

export default CardHead;
