import { useContext } from 'react';

import { isNumber } from 'util/helper';
import { MapContext } from 'context';
import { useMap } from 'hooks';

export const usePoint = () => {
  const properties = useContext(MapContext);
  const { zoomById, zoomToHome, zoomFitBoundById } = useMap();
  const { setActivePoint } = properties;

  const setMapPoint = (ids) => {
    // много поинтов
    if (Array.isArray(ids)) {
      setActivePoint(ids);
      zoomFitBoundById(ids);
      return;
    }

    // на начальный экран
    if (typeof ids === 'boolean') {
      setActivePoint(false);
      zoomToHome();
      return;
    }
    
    // один поинт
    const id = parseInt(ids)
    if (isNumber(id)) {
      setActivePoint(id);
      zoomById(id);
      return;
    }
  };

  return { ...properties, setMapPoint };
};
