import styles from './Patern.module.css';

export default function Pattern() {
  return (
    <div className={styles['text-block__pattern']}>
      <img
        className={styles['text-block__img']}
        src='icons/pattern_text_block.svg'
      />
    </div>
  );
}
