import { createContext, useState, useEffect } from 'react';
import React from 'react';

export const LangContext = createContext();

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState(() => {
        if (
            document.location
              .hash.replace('#', '')
              .toLocaleLowerCase() == "en"
        ) {
            localStorage.setItem("LANG", "EN");
            return "EN";
        };

        return localStorage.getItem("LANG") || "RU";
    });

    useEffect(() => {
        localStorage.setItem("LANG", lang);
        document.location.hash = lang == 'EN' ? 'en' : '';
    }, [lang]);

    return (
        <LangContext.Provider
            value={{
                lang,
                setLang,
            }}
        >
            {children}
        </LangContext.Provider>
    );
};
