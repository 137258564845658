import { useEffect, useState } from 'react';

import { useApp } from 'hooks';
import { sortByName } from '../util';

function usePersons() {
  const { persons: personsData } = useApp();
  const [persons, setPersons] = useState(sortByName(personsData));

  useEffect(() => {
    if (personsData) setPersons(personsData);
  }, [personsData]);

  return { persons, setPersons };
}

export { usePersons };
