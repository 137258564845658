import React, { createContext, useState } from "react";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  return (
    <MenuContext.Provider
      value={{ activeMenu, setActiveMenu, isOpenSidebar, setIsOpenSidebar }}
    >
      {children}
    </MenuContext.Provider>
  );
};
