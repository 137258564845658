import { useState } from 'react';

import { calcPositions } from 'util/helper';
import { isScrollEnd } from '../lib/util';
import { usePoint } from 'hooks';

export function useScrollElement(targetElementRefs, personData) {
  const { setMapPoint } = usePoint();
  const [scrollElement, setScrollElement] = useState();
  const [isScrollElement, setIsScrollElement] = useState(false);

  const handleScroll = (event) => {
    const parentElement = event.currentTarget;
    if (!isScrollElement) setIsScrollElement(true);

    if (isScrollElement) {
      const refs = targetElementRefs.current;
      const scrollEnd = isScrollEnd(parentElement);

      const parentRect = parentElement.getBoundingClientRect();
      // центр view элемента
      const parentMiddle = (parentRect.top + parentRect.bottom) / 2;

      // поиск блока который прошел центр элемента
      // возв индекс элемента из массива
      const indexElementInScroll = calcPositions(
        targetElementRefs.current,
        parentMiddle
      );

      const isCenterElement =
        indexElementInScroll >= 0 &&
        scrollElement !== indexElementInScroll &&
        !scrollEnd;

      // если элемент ближе всего от центра и еще не активен
      if (isCenterElement) {
        const targetElement = refs[indexElementInScroll];
        const idPoint = targetElement.getAttribute('element-id');
        setMapPoint(idPoint);
        setScrollElement(indexElementInScroll);
      }

      const isBeginElement =
        indexElementInScroll === -1 && scrollElement !== undefined;

      // когда карточку пролистали в начало
      if (isBeginElement) {
        const ids = personData.getPlacesId();
        setScrollElement(undefined);
        setMapPoint(ids);
      }

      const isLastElement =
        scrollEnd && indexElementInScroll !== refs.length - 1;

      // когда скролл дошел до конца но объект не попал в центр элемента
      if (isLastElement) {
        const indexLastElement = refs.length - 1;
        try {
          const targetElement = refs[indexLastElement];
          const idPoint = targetElement.getAttribute('element-id');
          setMapPoint(idPoint);
          setScrollElement(indexLastElement);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  return { setIsScrollElement, handleScroll };
}
