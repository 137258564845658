import clsx from 'clsx';
import styles from './CardBuilding.module.css';

export default function CardBuilding({
  prop_type = 'Поместье',
  cost,
  full_address = 'Не указан',
  name_owner = 'Не известно',
  prop_photo,
  ...other
}) {
  const imageSrc = prop_photo ? `estates/${prop_photo}` : 'image_building.png';
  const isArrest = other['arrest (yes/no)'] === 'yes' ? true : false;

  return (
    <div className={styles['card-building']}>
      <div className={clsx('separator', styles['separator-margin'])} />
      <div className={styles['card-building__title']}>
        <div className={styles['frame__icon']}>
          <span>{prop_type}</span>
        </div>
        <div className={styles['card-building__arrest-block']}>
          {isArrest && <div className={styles['card-building__arrest']} />}
        </div>
      </div>
      <div className={styles['card-building__frame']}>
        <img
          src={`/images/${imageSrc}`}
          alt=''
          loading="lazy"
          className={styles['card-building__img']}
        />
      </div>
      {cost && (
        <div className={styles['card-building__cost']}>
          <div className={styles['card-building__cost__title']}>Стоимость:</div>
          <div className={styles['card-building__cost__text']}>{cost} $</div>
        </div>
      )}
      <div className={styles['card-building__address']}>
        <div className={styles['card-building__address__title']}>Адрес:</div>
        <div className={styles['card-building__address__text']}>
          {full_address}
        </div>
      </div>
      <div className={styles['card-building__note']}>
        {/* <div className={styles["card-building__note__title"]}>Записано на:</div> */}
        <div className={styles['card-building__note__text']}>{name_owner}</div>
      </div>
      {/* <div className={styles["card-building__description"]}>{description}</div> */}
    </div>
  );
}
