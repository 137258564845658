import React, { createContext, useState } from 'react';
export const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [colorLangFooter, setColorLangFooter] = useState(false);
  const [colorLangMap, setColorLangMap] = useState(false);

  const handlerColorLang = (val, component) => {
    if (component === 'footer') {
      if (colorLangFooter !== val) setColorLangFooter(val);
    } else if (component === 'map') {
      if (colorLangMap !== val) setColorLangMap(val);
    }
  };

  let colorLang = colorLangFooter || colorLangMap;

  return (
    <UIContext.Provider value={{ colorLang, setColorLang: handlerColorLang }}>
      {children}
    </UIContext.Provider>
  );
};
