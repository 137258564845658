import { startsWith, toLower, compose, map } from 'rambda';

import { usePersons } from './usePersons';

function useSearch() {
  const { persons, setPersons } = usePersons();

  function searchPersons(arrayStr) {
    const strStarWith = compose(startsWith, toLower);
    const isStrStartToQuery = (searchQuery) =>
      compose(strStarWith(searchQuery), toLower);

    const results = persons.map((item) => ({ ...item, show: false }));

    return map((person) => {
      const { name, surname } = person;

      // проверка есть ли поисковые слова в имени и фамилии
      const nameResult = arrayStr.some((str) => isStrStartToQuery(str)(name));
      const surnameResult = arrayStr.some((str) =>
        isStrStartToQuery(str)(surname)
      );

      // если больше двух слов ввели то должно совпасть с именем и фамилией
      if (arrayStr.length > 1) {
        if (nameResult && surnameResult) return { ...person, show: true };
      } else {
        if (nameResult || surnameResult) return { ...person, show: true };
      }

      return { ...person, show: false };
    }, results);
  }

  const handleSearch = (term = '') => {
    if (!term.length) {
      setPersons(persons.map((item) => ({ ...item, show: true })));
    } else {
      let result = searchPersons(term);
      setPersons(result);
    }
  };

  return { persons, handleSearch };
}

export { useSearch };
