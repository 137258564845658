import { useApp } from 'hooks';
import styles from './ChangeLanguage.module.css';
import clsx from 'clsx';
import { useContext } from 'react';
import { UIContext, LangContext } from 'context';

export default function ChangeLanguage() {
  const { isPageText } = useApp();
  const { colorLang } = useContext(UIContext);
  const { lang, setLang } = useContext(LangContext);
  
  return (
    <div
      className={clsx(
        styles['navigation__language'],
        (isPageText || colorLang) && styles['white']
      )}
      onClick={() => setLang(lang === 'RU' ? 'EN' : 'RU')}
    >
      {lang === 'RU' ? 'EN' : 'RU'}
    </div>
  );
}
