import TitleHome from 'components/atoms/TitleHome';
import styles from './HomeScreen.module.css';
import Navigation from '../Navigation';
import { useApp } from 'hooks';

export default function HomeScreen() {
  const { isMobile } = useApp();
  return (
    <div className={styles['main']}>
      {isMobile && <Navigation />}
      <div className={styles['background']} />
      <TitleHome />
      <div className={styles['frame']}>
        <div className={styles['frame__line-top']} />
        <div className={styles['frame__line-left']} />
        <div className={styles['frame__line-right']} />
      </div>
      <div className={styles['left-part']}>
        <div className={styles['left-part__container']}>
          <div className={styles['castle-block-left']}>
            <div className={styles['castle-block-left__container']}>
              <div className={styles['castle-block-left__castle']} />
              <div className={styles['castle-block-left__bush']} />
            </div>
          </div>
          <div className={styles['forest-left']} />
          <div className={styles['ship-left']} />
        </div>
      </div>

      <div className={styles['right-part']}>
        <div className={styles['rigth-part__container']}>
          <div className={styles['castle-block-right']}>
            <div className={styles['castle-block-right__container']}>
              <div className={styles['castle-block-right__castle']} />
              <div className={styles['castle-block-right__bush']} />
            </div>
          </div>
          <div className={styles['forest-right']} />
          <div className={styles['ship-right']} />
        </div>
      </div>
    </div>
  );
}
