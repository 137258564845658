import { LngLatBounds } from 'mapbox-gl';

export function findPlacesByRef(places, ref) {
  if (Array.isArray(ref)) {
    const refs = ref.map((item) => parseInt(item));
    return places.filter(({ properties }) =>
      refs.includes(parseInt(properties.ref))
    );
  }
  return places.filter(
    ({ properties }) => parseInt(properties.ref) === parseInt(ref)
  );
}

export function getCoordinates(item) {
  return item.geometry.coordinates;
}

export function findClosestToCenterElement(Positions) {
  let closestNegativeToZero = Infinity;
  let indexElementInScroll = -1;

  // Finds the element that is visible and closest to the center.
  Positions.forEach((number, index) => {
    if (number < 0 && Math.abs(number) < Math.abs(closestNegativeToZero)) {
      closestNegativeToZero = number;
      indexElementInScroll = index;
    }
  });

  return indexElementInScroll;
}

export function calcPositionsRelativeToCenter(nodes, middle) {
  const result = [];

  nodes.forEach((childElement, i) => {
    const rect = childElement.getBoundingClientRect();
    const positionRelativeToCenter = rect.top - middle;

    result.push(positionRelativeToCenter);
  });

  return result;
}

export function calcPositions(nodes, middle) {
  let closestNegativeToZero = Infinity;
  let indexElementInScroll = -1;
  const length = nodes.length;

  try {
    for (let i = 0; i < length; i++) {
      const childElement = nodes[i];
      const rect = childElement.getBoundingClientRect();
      const position = rect.top - middle;

      if (
        position < 0 &&
        Math.abs(position) < Math.abs(closestNegativeToZero)
      ) {
        closestNegativeToZero = position;
        indexElementInScroll = i;
      } else {
        break;
      }
    }
  } catch (e) {
    console.error('calcPositions ', e);
  }

  return indexElementInScroll;
}

export function getBounds(coordinates) {
  return coordinates.reduce((bounds, coord) => {
    return bounds.extend(coord);
  }, new LngLatBounds(coordinates[0], coordinates[0]));
}

export function fetchData(url) {
  return new Promise((resolve, reject) => {
    // В этом примере мы использовали Fetch API для запроса данных
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}

export function areArraysEqual(arr1, arr2) {
  // Проверка на равное количество подмассивов
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Проверка каждого подмассива
  for (let i = 0; i < arr1.length; i++) {
    const subArr1 = arr1[i];
    const subArr2 = arr2[i];

    // Проверка на равное количество элементов в подмассиве
    if (subArr1.length !== subArr2.length) {
      return false;
    }

    // Проверка каждого элемента в подмассиве
    for (let j = 0; j < subArr1.length; j++) {
      if (subArr1[j] !== subArr2[j]) {
        return false;
      }
    }
  }

  // Если все проверки прошли успешно, массивы считаются равными
  return true;
}

export function checkActivePoint(points, idPoint) {
  let isFindPoint = false;
  idPoint = parseInt(idPoint);

  if (typeof points === 'boolean') return points;

  if (Array.isArray(points)) {
    isFindPoint = points.includes(idPoint);
  } else {
    isFindPoint = Number(idPoint) === Number(points);
  }

  return isFindPoint;
}

export function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

// https://www.30secondsofcode.org/js/s/throttle-function/
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function () {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function () {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export function debounce(func, ms) {
  let timeout;
  return function() {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, arguments), ms);
  };
}

export const checkArest = (obj) => obj['arrest (yes/no)'] === 'yes';
