import { forwardRef } from 'react';

import styles from './blockText.module.css';

const BlockText = forwardRef(
  ({ description, title, id, images = false }, ref) => {
    const titleBlock = (
      <>
        <div className={styles['block-text__img']}>
          <img
            className={styles['icon']}
            src='/icons/pattern_text_center.svg'
          />
        </div>
        <div className={styles['block-text__title']}>{title}</div>
      </>
    );

    const getImage = (isDouble) => (value, i) =>
      (
        <div
          key={i}
          className={styles['images__container']}
          style={{
            backgroundImage: `url('/images/estates/${value}')`,
            ...(isDouble && { width: '49.4%' }),
            ...(i === 1 && { left: 'unset' }),
          }}
        ></div>
      );

    return (
      <div
        className={styles['block-text']}
        ref={ref}
        {...(id && { 'element-id': id })}
      >
        <div className={styles['block-text__container']}>
          {title && titleBlock}
          {images && (
            <div className={styles['block__images']}>
              {images.length === 2
                ? images.map(getImage(true))
                : images.map(getImage(false))}
            </div>
          )}
          <div className={styles['block-text__description']}>{description}</div>
        </div>
      </div>
    );
  }
);

export default BlockText;
