import styles from './CloseCard.module.css';

import { ReactComponent as SvgIcon } from 'assets/close_icon.svg';

export default function CloseCard({ handleClose }) {
  return (
    <div className={styles['close-element']}>
      <div className={styles['close-element-block']}>
        <div className={styles['close-element-wrapper']}>
          <div className={styles['close-element-icon']} onClick={handleClose}>
            <SvgIcon className={styles['icon']} />
          </div>
        </div>
      </div>
    </div>
  );
}
