import useSWRImmutable from 'swr/immutable';
import { fetchData } from 'util/helper';
import { useContext, useState, useEffect } from 'react';
import { LangContext } from 'context';

export const useText = () => {
  const { lang, setLang } = useContext(LangContext);

  const ruResp = useSWRImmutable(
    '/data/app/text.ru.json',
    fetchData
  );
  const enResp = useSWRImmutable(
    '/data/app/text.en.json',
    fetchData
  );
  const [texts, setTexts] = useState([]);

  useEffect(() => {
    if (lang == "RU")
      setTexts(!ruResp.isLoading && ruResp.data ? ruResp.data : [])
    else
      setTexts(!enResp.isLoading && enResp.data ? enResp.data : [])
  }, [lang, enResp.isLoading, ruResp.isLoading])

  return { texts };
};
