export const isScrollEnd = (element) => {
  const diff = Math.abs(
    element.scrollHeight - element.scrollTop - element.clientHeight
  );

  return diff <= 10;
};


export function scrollToElement(refs, idPoint) {
  if (!Array.isArray(idPoint) && idPoint) {
    try {
      const showElement = refs.find(
        (element) => element.getAttribute("element-id") === String(idPoint)
      );

      if (showElement) showElement.scrollIntoView();

    } catch (e) {
      console.error("Card not building", e);
    }
  }
}
