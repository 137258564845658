import 'normalize.css';
import './App.css';

import { MapProvider, MenuProvider, AppProvider, UIProvider, LangProvider } from 'context';
import Home from 'components/page/Home/Home.js';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useResizeHome } from 'hooks';

const App = () => {
  useResizeHome();

  return (
    <LangProvider>
      <AppProvider>
        <MapProvider>
          <MenuProvider>
            <UIProvider>
              <Home />
            </UIProvider>
          </MenuProvider>
        </MapProvider>
      </AppProvider>
    </LangProvider>
  );
};

export default App;
