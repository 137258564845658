import { useState } from 'react';

function Search({ callback, clearData }) {
  const [value, setValue] = useState();
  // let input = useRef();

  // if (clearData) {
  //   input.current.value = '';
  // }

  const sendData = (data) => {
    // let data = input.current.value;
    callback(data.trim().split(' '));
    // input.current.value = '';
  };

  const handleClick = () => sendData();

  const handleChange = (e) => {
    setValue(e.target.value);
    sendData(e.target.value);
  };

  return (
    <div className='search'>
      <div className='search-block'>
        <img
          className='search-icon'
          alt=''
          src='/icons/search_icon.svg'
          onClick={handleClick}
        />
        <input
          // ref={input}
          value={value}
          onChange={handleChange}
          className='search-input'
          placeholder='Поиск...'
        />
      </div>
    </div>
  );
}

export default Search;
