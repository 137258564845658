import { useContext } from "react";

import { MenuContext } from "context";

export const useMenu = () => {
  /**
   * @type {[number|boolean, React.Dispatch<MyType>]} state
   */
  const obj = useContext(MenuContext);

  return obj;
};
