import { useRef, lazy, Suspense } from 'react';

import styles from './text.module.css';
import BlockText from 'components/atoms/BlockText';
import TextTemplate from 'components/atoms/TextTemplate';
import HomeScreen from 'components/molecules/HomeScreen';
import { useScrollElement } from './hooks/useScrollElement';
import Footer from 'components/atoms/Footer';
import InvitationToMap from 'components/molecules/InvitationToMap';
import { useText } from './api';
import { useUI } from 'hooks';

const LazyComponent = lazy(() =>
  import('components/organisms/MapHome/MapHome')
);

function TextPage() {
  const targetElementsRefs = useRef([]);
  const { scrollElement, handleScroll } = useScrollElement(targetElementsRefs);
  const { texts } = useText();
  const { ref } = useUI('map');

  const img = <img className='map-image' src={'/images/map_plug.png'} loading="lazy" />;

  return (
    <div className={styles['text__container']} onScroll={handleScroll}>
      <HomeScreen />
      <TextTemplate />
      <div className={styles['text__main']}>
        <div className={styles['text__sticky']}>
          <div ref={ref} className={styles['text__map']}>
            <Suspense fallback={img}>
              <LazyComponent element={texts[scrollElement]} />
            </Suspense>
          </div>
          <div className={styles['text__background-block']}>
            <div className={styles['text__fill-free']} />
            <div className={styles['text__radial']} />
            <div className={styles['text__fill-one']} />
          </div>
        </div>
        {texts.map((text, i) => {
          return (
            <BlockText
              ref={(ref) => (targetElementsRefs.current[i] = ref)}
              key={i}
              title={text.title}
              description={text.description}
              id={i}
              images={text.images}
            />
          );
        })}
        <div className={styles['map-plug']} />
      </div>
      <InvitationToMap />
      <Footer />
    </div>
  );
}

export default TextPage;
