import Pattern from 'shared/UI/Pattern';
import styles from './InivitationToMap.module.css';
import ToCatalog from 'components/atoms/ToCatalog';
import { useContext } from 'react';
import { LangContext } from 'context';

const localization = {
  text1: {
    en: 'Novaya Gazeta Europe, in collaboration with Transparency International Russia and project Sistema has identified over 150 properties owned by the Russian elite, including beachfront villas, mansions, and vineyards. However, only 24 of these properties have been frozen, and only a few have been seized.',
    ru: 'Всего «Новая газета Европа» вместе с «Трансперенси Интернешнл — Россия» и проектом «Система» насчитала больше 150 объектов недвижимости, принадлежащих российской элите: от вилл на берегу до настоящих поместий и огромных виноградников. Только 24 объекта заморожены и всего несколько — конфискованы.'
  },
  text2: {
    en: 'More about luxury properties in France owned by wealthy Russians can be found on the map:',
    ru: 'Больше о роскошной недвижимости россиян во Франции можно узнать, изучив карту:'
  }
};

export default function InvitationToMap() {
  const { lang } = useContext(LangContext);
  
  return (
    <div className={styles['body']}>
      <div className={styles['wrapper']}>
        <div className={styles['pattern']}>
          <Pattern />
        </div>
        <p className={styles['text']}>
          {lang === 'RU' ? localization.text1.ru : localization.text1.en}
          <br />
          <br />
          {lang === 'RU' ? localization.text2.ru : localization.text2.en}
        </p>
        <ToCatalog home={true} />
      </div>
    </div>
  );
}
