import Sidebar from "components/molecules/Sidebar/Sidebar";
import Main from "components/templates/Main";
import PropTypes from "prop-types";
import clsx from "clsx";

export default function Catalog({ isShowSidebar, isPageText }) {
  return (
    <div className={clsx("app-block", !isPageText && "app-block_move")}>
      <div
        className={clsx(
          "app-sidebar",
          isPageText ? "app-sidebar_move" : "app-sidebar_leav"
        )}
      >
        <Sidebar isShowSidebar={isShowSidebar} />
      </div>
      <div className="app-main">
        <Main isShowCard={isShowSidebar} />
        <div className={clsx(!isPageText && "app-main__blur")} />
      </div>
    </div>
  );
}

Catalog.propTypes = {
  isShowSidebar: PropTypes.bool,
  isPageText: PropTypes.bool,
};
