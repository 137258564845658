import { useApp } from 'hooks';
import styles from './ToCatalog.module.css';
import clsx from 'clsx';
import { useContext } from 'react';
import { LangContext } from 'context';

export default function ToCatalog({ home }) {
  const { isPageText, setIsPageText } = useApp();
  const { lang } = useContext(LangContext);

  return (
    <div
      className={clsx(styles['navigation__button'], home && styles['home'])}
      onClick={() => setIsPageText(!isPageText)}
    >
      {
        isPageText && !home ? 
          (lang === 'RU' ? 'К Тексту' : 'To text')
          : (lang === 'RU' ? 'К Карте' : 'To map')
      }
    </div>
  );
}
