import clsx from 'clsx';

import Catalog from 'components/templates/Catalog/Catalog.js';
import Text from 'components/templates/Text/Text';
import Navigation from 'components/molecules/Navigation';

import './home.css';
import { useApp } from 'hooks';

function Home() {
  const { isPageText, isMobile } = useApp();

  return (
    <div className='main'>
      {!isMobile && <Navigation />}
      <Text />
      <div
        className={clsx('main__map', isPageText ? 'main_move' : 'main_home')}
      >
        <Catalog isShowSidebar={isPageText} isPageText={isPageText} />
      </div>
    </div>
  );
}

export default Home;
