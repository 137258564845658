import React, { createContext, useRef, useState } from 'react';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [activePoint, setActivePoint] = useState(false);
  const [isOpenMap, setIsOpenMap] = useState(false);

  const mapRef = useRef(null);

  return (
    <MapContext.Provider
      value={{
        mapRef,
        activePoint,
        setActivePoint,
        isOpenMap,
        setIsOpenMap,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
