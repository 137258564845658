import { useEffect, useState } from 'react';
import { calcPositions, debounce } from 'util/helper';

const viewportHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

const positionScreenWhenUpdate = 0.24;
const halfViewportHeight = viewportHeight * positionScreenWhenUpdate;

export const useScrollElement = (refs) => {
  const [scrollElement, setScrollElement] = useState(-1);

  const handleScroll = debounce((event) => {
    if (refs.current) {
      const position = calcPositions(refs.current, halfViewportHeight);
      if (position >= 0 && scrollElement !== position) {
        setScrollElement(position);
      }
      if (position === -1 && scrollElement !== -1) {
        setScrollElement(-1);
      }
    }
  }, 50);

  return { scrollElement, handleScroll };
};
