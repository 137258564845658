const config = {
  zoomElement: 14,
  zoomStandart: 5,
  mapboxAccessToken:
    'pk.eyJ1IjoibmdlLW1hcGJveCIsImEiOiJjbHBzZm5hY2gwM2hoMmp0N2d1dHNnYXNwIn0.9BHaGlEWcGvtt870RaBeYw',
  mapboxAccessTokenCardCatalog:
    'pk.eyJ1IjoibmdlLW1hcGJveCIsImEiOiJjbHBzZm5hY2gwM2hoMmp0N2d1dHNnYXNwIn0.9BHaGlEWcGvtt870RaBeYw',
  mapStyle: 'mapbox://styles/nge-mapbox/clsbpi8p301jw01pea75qf0ix',
  mapStyleCatalog: 'mapbox://styles/nge-mapbox/clsuiv6pb000s01qu9sozb3py',
  initialViewState: {
    latitude: 46.6031,
    longitude: 6.8883,
    zoom: 5,
    center: [6.8883, 46.6031]
  },
  initialViewStateMobile: {
    latitude: 46.6031,
    longitude: 1.8883,
    zoom: 4,
    center: [1.8883, 46.6031],
    padding: { top: 30, bottom: 30 },
  },
  regionsURL: {
    provence: 'data/coordinates/region-provence-alpes-cote-d-azur.json',
    ileDeFrance: 'data/coordinates/ile-de-france.json',
    normandie: 'data/coordinates/hauts-de-france-and-normandie.json',
    alpes: 'data/coordinates/auvergne-rhone-alpes.json',
    nouvelleAquitaine: 'data/coordinates/nouvelle-aquitaine.json',
  },
};

export default config;
