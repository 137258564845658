import { animated, useTransition } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { usePoint, useMenu, useApp } from 'hooks';
import CardBuilding from 'components/atoms/CardBuilding';
import CardHead from 'components/atoms/CardHead';
import CloseCard from 'components/atoms/CloseCard';
import { scrollToElement } from './lib/util';
import styles from './Card.module.css';
import { useScrollElement } from './hooks/useScrollElement';

function Card({ isShowCard = true }) {
  const { persons, isMobile, places } = useApp();
  const { activeMenu, setActiveMenu, isOpenSidebar, setIsOpenSidebar } =
    useMenu();
  const { activePoint, setMapPoint, isOpenMap, setIsOpenMap } = usePoint();
  const targetElementRefs = useRef([]);
  const cardRef = useRef(null);
  const person = persons.find(({ idPerson }) => idPerson === activeMenu);
  if (person) {
    person.getPlacesId = function () {
      return this.places
        .map((idPlace) => places[idPlace]?.idPoint)
        .filter(Boolean);
    };
  }

  const { handleScroll, setIsScrollElement } = useScrollElement(
    targetElementRefs,
    person
  );

  const isOpen = Boolean(isShowCard && person);

  const transitions = useTransition(isOpen && person, {
    from: {
      transform: 'translateX(-100%)',
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: {
      transform: 'translateX(-100%)',
    },
    config: {
      duration: 500,
    },
  });

  // скролл до элемента при клики на карте
  useEffect(() => {
    if (isOpenMap && !isOpenSidebar) {
      setIsScrollElement(false);
      scrollToElement(targetElementRefs.current, activePoint);
      setIsOpenMap(false);
    }
  }, [activeMenu, activePoint, isOpenMap, isOpenSidebar, setIsOpenMap]);

  // открытие карточки с sidebar
  useEffect(() => {
    if (!isMobile) {
      activePoints();
    }
  }, [activeMenu]);

  const handleClose = () => {
    if (!isMobile) {
      setMapPoint(false);
    } else {
      activePoints();
    }
    setActiveMenu(false);
    setIsOpenSidebar(false);
  };

  const activePoints = () => {
    if (isOpenSidebar && person) {
      const isManyPlaces = person.places.length > 1;
      // если объектов больше 1
      if (isManyPlaces && !isMobile) {
        const placesIds = person.getPlacesId();
        setMapPoint(placesIds);
      } else {
        const [id] = person.getPlacesId();
        if (id) {
          setMapPoint(id);
        }
      }
    }
  };

  return transitions((style, item) => {
    if (!item) return;
    targetElementRefs.current = [];

    const { ref: idGoogleTable, ...other } = item;
    return (
      <div className={styles['card']} ref={cardRef}>
        <animated.div style={style} className={styles['card__block']}>
          <div
            className={styles['card__wrapper']}
            onScroll={item.places.length > 1 ? handleScroll : undefined}
          >
            <div className={styles['card__body']}>
              <CloseCard handleClose={handleClose} />
              <CardHead {...other} />
              {Boolean(item.places.length) &&
                item.places.map((idPlace, i) => {
                  const place = places[idPlace];
                  const { ref: idGoogleTable, ...other } = place.properties;
                  return (
                    <div
                      key={place.idPoint}
                      ref={(element) =>
                        (targetElementRefs.current[i] = element)
                      }
                      element-id={place.idPoint}
                    >
                      <CardBuilding {...other} />
                    </div>
                  );
                })}
            </div>
          </div>
        </animated.div>
      </div>
    );
  });
}

Card.propTypes = {
  isShowCard: PropTypes.bool,
};

export default Card;
