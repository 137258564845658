import Pattern from 'shared/UI/Pattern';
import styles from './textTemplate.module.css';
import { useContext } from 'react';
import { LangContext } from 'context';

const linkForbes = `https://www.forbes.ru/milliardery/457415-francia-i-velikobritania-nacnut-iskat-imusestvo-rossijskih-oligarhov`;
const linkSvoboda = `https://www.svoboda.org/a/frantsiya-bez-oligarhov-spisok-zamorozhennoy-rossiyskoy-nedvizhimosti/31950278.html`;

export default function TextTemplate() {
  const { lang } = useContext(LangContext);

  return (
    <div className={styles['text-block']}>
      <div className={styles['text-block__body']}>
        <div className={styles['pattern-block']}>
          <Pattern />
        </div>
        { lang === 'RU' ?
          <p>
            
            После начала полномасштабной войны в Украине многие российские
            олигархи, чиновники, члены их семей и их доверенные лица попали под
            санкции Евросоюза. 28 февраля прошлого года две страны — Франция, где
            так любили покупать недвижимость представители российской элиты, и
            Великобритания —{' '}<a href={linkForbes}>объявили</a>, что начинают искать
            имущество олигархов для его ареста. К лету Франция{' '}
            <a href={linkSvoboda}>начала замораживать</a> роскошную недвижимость
            россиян, среди первых попавших под ограничения — второй муж Людмилы
            Путиной, владелец “Альфа-групп” Михаил Фридман, приближенный к
            Владимиру Путину олигарх Аркадий Ротенберг, взявший на себя
            ответственность за дворец президента в Геленджике и другие. :               
          </p> :
          <p>
            
            After Russia's full-scale invasion of Ukraine began, many Russian oligarchs, 
            officials, their family members, and their proxies came under EU sanctions. 
            On 28 February last year, France and the UK{' '}<a href={linkForbes}>announced</a>{' '} 
            that they would begin searching for oligarchs' assets to seize. By the summer, 
            France had{' '}<a href={linkSvoboda}>begun to freeze</a> Russian-owned luxury real estate, 
            including properties belonging to the second husband of Putin's ex-wife Lyudmila Putina, 
            Alfa-Group owner Mikhail Fridman, and Putin's close friend Arkady Rotenberg.              
        </p>
        }
        <br />
        { lang === 'RU' ?
          <p>
            Франция действительно была и остается одним из любимых мест российской элиты. Именно обеспеченные россияне заселили Сен-Жан-Кап-Ферра — один из самых роскошных курортов мира на Лазурном берегу, писали в 2021 году «Важные истории». Владельцы вилл регистрировали их на созданные специально для этого фирмы — это обычная практика среди тех, кто хочет
            купить недвижимость во Франции. При этом французский реестр владельцев недвижимости остается одним из самых закрытых в Европе: компания, владеющая недвижимостью, не всегда указывает ее в своих документах, а чтобы получить выписку по каждому объекту, нужно потратить как минимум 12 евро только на оплату пошлины, сам же запрос надо направить почтой во Францию.
          </p> :
          <p>
            France has long been one of the Russian elite's favourite destinations. Wealthy Russians have notably flocked to Saint-Jean-Cap-Ferrat, one of the world's most luxurious resorts on the Côte d'Azur, as Russian independent news outlet IStories reported in 2021. These villa owners often register their properties through firms set up specifically for this 
            purpose, a common practice among those purchasing property in France. The French property register remains one of the most inaccessible in Europe, as the company owning the property does not always declare it in its documents. Obtaining an extract for each property involves paying a fee of at least €12 and sending the request by post to France.
          </p> 
        }
        <br />
        { lang === 'RU' ?
          <p>
            «Новая газета Европа» и расследовательский проект «Система» получили доступ к датасету, содержащему имена более 150 обеспеченных и связанных с властью россиян, которые владеют элитной недвижимостью во Франции. Данные были собраны исследователями Transparency International — France и Anti-Corruption Data Collective. Некоторые из этих россиян уже находятся под санкциями, другие же не попали под ограничения, хотя поддерживают российскую агрессию (либо на словах, либо сотрудничая с российской армией). Многие из них продолжают владеть недвижимостью в Ницце, на Лазурном берегу, в Париже или Альпах. Далеко не вся эта недвижимость арестована.
          </p> : 
          <p>
            Novaya Gazeta Europe and project Sistema have obtained a dataset from Transparency International France and the Anti-Corruption Data Collective, containing the names of over 150 wealthy and influential Russians who own luxury property in France. Some of these individuals are already under sanctions, while others, despite supporting Russian aggression either verbally or by cooperating with the Russian army, are not. Many still own properties in Nice, on the Côte d'Azur, in Paris, or in the Alps, few of which have been seized.
          </p> 
        }
      </div>
    </div>
  );
}
