import React, { useState, useEffect, lazy, Suspense } from 'react';
import Card from 'components/organisms/Card';
import Navigation from 'components/molecules/Navigation';
import PortalComponent from 'shared/PortalComponent';
import { useApp, useMenu } from 'hooks';

const LazyComponent = lazy(() => import('components/organisms/MapCatalog'));

function Main({ isShowCard }) {
  const [hasRendered, setHasRendered] = useState(false);
  const { activeMenu } = useMenu();
  const { isMobile } = useApp();

  useEffect(() => {
    if (isShowCard) {
      setHasRendered(true);
    }
  }, [isShowCard]);

  const img = (
    <img className='map-image' src={'/images/map_plug.png'} loading='lazy' />
  );

  return (
    <div className='main-block'>
      {isMobile && isShowCard && activeMenu ? (
        <PortalComponent>
          <Card isShowCard={isShowCard} />
        </PortalComponent>
      ) : (
        <Card isShowCard={isShowCard} />
      )}
      {isMobile && <Navigation />}

      <div className='main-map'>
        <div className='map-block'>
          {isShowCard || hasRendered ? (
            <Suspense fallback={img}>
              <LazyComponent />
            </Suspense>
          ) : (
            <img
              className='map-image'
              loading='lazy'
              src={'/images/map_plug.png'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Main;
