export function sortByName(array) {
  return array.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // приводим к верхнему регистру для учета регистра
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}
