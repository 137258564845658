import { useEffect, useState } from 'react';
import clsx from 'clsx';

import Search from 'components/atoms/Search';
import styles from './Sidebar.module.css';

import { useMenu } from 'hooks';
import { useSearch } from './hooks/useSearch';

function Sidebar() {
  const { activeMenu, setActiveMenu, setIsOpenSidebar } = useMenu();
  const [clearInput, setClearInput] = useState(false);
  const { persons, handleSearch } = useSearch();

  useEffect(() => {
    if (activeMenu === false) {
      setClearInput(true);
    }
  }, [activeMenu]);

  const handleActiveCard = (e) => {
    const idPerson = parseInt(e.currentTarget.getAttribute('data-person-id'));
    setActiveMenu(idPerson);
    setIsOpenSidebar(true);
  };

  return (
    <div className={styles['sidebar-container']}>
      <div className={styles['sidebar']}>
        <div className={styles['search-block']}>
          <Search callback={handleSearch} clearData={clearInput} />
        </div>
        <div className={styles['sidebar__block']}>
          {persons &&
            persons.map((person, i) => {
              if (person.show !== false && person.name)
                return (
                  <div
                    key={i}
                    className={clsx(
                      styles['person-block'],
                      person.idPerson === activeMenu &&
                        styles['person-block_active']
                    )}
                    data-person-id={person.idPerson}
                    onClick={handleActiveCard}
                  >
                    <div className={styles['person-fio']}>
                      <div className={styles['person-name']}>
                        {person.name} {person.surname}
                      </div>
                      {person.owner_group_ru && (
                        <div className={styles['person-owner-group']}>
                          {person.owner_group_ru}
                        </div>
                      )}
                    </div>
                  </div>
                );
              return null;
            })}
          {typeof searchResult === 'string' && (
            <div>
              <div>Ничего не найдено </div>
              <div> {'searchResult'}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
