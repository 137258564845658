import ToCatalog from 'components/atoms/ToCatalog';
import ChangeLanguage from 'components/atoms/ChangeLanguage';
import styles from './Navigation.module.css';

export default function Navigation() {
  return (
    <div className={styles.navigation}>
      <ChangeLanguage />
      <ToCatalog />
    </div>
  );
}
