import { useContext } from 'react';

import { MapContext } from 'context';
import { useApp } from 'hooks';
import { getBounds, getCoordinates } from 'util/helper';
import config from '../config';

const durationFly = 800;
const essential = false;

export const useMap = () => {
  const { mapRef, ...other } = useContext(MapContext);
  const { placesIdPoint, isMobile } = useApp();
  const viewState = isMobile
    ? config.initialViewStateMobile
    : config.initialViewState;

  const zoomToElement = (obj) => {
    const { center } = obj;

    try {
      mapRef.current.flyTo({
        center,
        zoom: obj.zoom || config.zoomElement,
        essential,
        duration: durationFly,
      });
    } catch (e) {
      console.error('useMap zoomToElement flyTo :>>', e);
    }
  };

  const zoomToHome = () => {
    const viewObj = Object.assign(viewState, {
      essential,
      duration: durationFly,
    });

    try {
      mapRef.current.flyTo(viewObj);
    } catch (e) {
      console.error('useMap zoomToHome flyTo :>>', e);
    }
  };

  const zoomById = (idPoint) => {
    const place = placesIdPoint[idPoint];

    const center = place.geometry.coordinates.slice(0, 2);

    try {
      mapRef.current.flyTo({
        center,
        zoom: config.zoomElement,
        essential,
        duration: durationFly,
      });
    } catch (e) {
      console.error('useMap zoomById flyTo :>>', e);
    }
  };

  const zoomFitBounds = (coordinates) => {
    // https://stackoverflow.com/questions/63056472/mapbox-fitbounds-invalid-lnglat-object-nan-nan
    const bounds = getBounds(coordinates);

    try {
      mapRef.current.fitBounds(bounds);
    } catch (e) {
      console.error('useMap zoomFitBounds fitBounds :>>', e);
    }
  };

  const zoomFitBoundById = (ids) => {
    ids = ids.filter(Boolean);
    const points = ids.map((idPoint) => placesIdPoint[idPoint]);
    if (!points.length) return;
    const coordinates = points.map(getCoordinates);
    zoomFitBounds(coordinates);
  };

  return {
    mapRef,
    zoomToElement,
    zoomFitBounds,
    zoomById,
    zoomToHome,
    zoomFitBoundById,
    ...other,
  };
};
