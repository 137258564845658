import styles from "./TitleHome.module.css";
import { useContext } from 'react';
import { LangContext } from 'context';

export default function TitleHome() {
  const { lang, setLang } = useContext(LangContext);

  return (
    <div className={styles["text-main__block"]}>
      <div className={styles["text-main__pattern"]}>
        <img
          className={styles["text-main__pattern-img"]}
          src="icons/pattern_top_home.svg"
        />
      </div>
      <div className={styles["text-main__title"]}>
        {
          lang == 'RU' ? 
            'Заголовок длинный четыре слова' : 
            'The title is four words long'
        }
      </div>
      <div className={styles["text-main__description"]}>
        {
          lang == 'RU' ? 
            'Какие нехорошие у нас все-таки олигархи: рассказывает Новая-газета' : 
            'How bad our oligarchs really are: says Novaya Gazeta'
        }
      </div>
    </div>
  );
}
